const dayjs = require('dayjs');
require('dayjs/locale/fr');

dayjs.locale('fr');

/**
 * Conversion of the date into a human readable format
 * @param date // date to be converted
 * @returns {string} // date converted
 */
function convertDateToReadableDate(date) {
  return dayjs(date).format('ddd D MMM');
}

/**
 * Conversion of dates into a human-readable format
 * @param start // start date to be converted
 * @param end // end date to be converted
 * @returns {string} // Return dates in a readable format
 */
function convertDateStartDateEndToLisibleDate(start, end) {
  if (start === end) {
    return dayjs(start).format('ddd D MMM');
  } else {
    const startConvert = dayjs(start);
    const endConvert = dayjs(end);

    if (startConvert.get('month') === endConvert.get('month')) {
      return `Du ${startConvert.format('ddd D')} au ${endConvert.format(
        'ddd D MMM',
      )}`;
    }
    return `Du ${startConvert.format('ddd D MMM')} au ${endConvert.format(
      'ddd D MMM',
    )}`;
  }
}

/**
 * Verification that the mission is in progress or not
 * @param start // Start date of the mission
 * @param end // End date of the mission
 * @returns {boolean} // Returns true if in progress otherwise false
 */
function currentMission(start, end) {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const now = dayjs();

  return (
    now >= startDate && now <= endDate.set('date', endDate.get('date') + 1)
  );
}

function taskToDo(end) {
  const endDate = dayjs(end);
  const now = dayjs();
  return endDate.set('date', endDate.get('date') + 1) > now;
}

function pastDate(end) {
  const endDate = dayjs(end);
  const now = dayjs();
  return now.diff(endDate, 'd') > 0;
}

function futureDate(start) {
  const startDate = dayjs(start);
  const now = dayjs();
  return now.diff(startDate, 'd', true) < 0;
}

export {
  convertDateToReadableDate,
  convertDateStartDateEndToLisibleDate,
  currentMission,
  taskToDo,
  pastDate,
  futureDate,
};
