<template>
  <ion-card
    color="light"
    class="card-planning-shadow"
    :router-link="'/chantier/' + this.mission.id_reservation + '/' + this.mission.id"
  >
    <background-header type="worksite" :mission="makeMissionOrder()" />

    <ion-card-content class="padding-card-ion-card-content">
      <ion-grid>
        <ion-row>
          <Date :date="date" />
          <DayPart :part="mission.day_part"></DayPart>
          <Banner v-if="mission.rental_coupons" />
        </ion-row>

        <informations-builder-and-site
          type="worksite"
          :builder="mission.builder"
          :name="mission.worksite_name"
          :city="mission.worksite_city"
          :manual="mission.worksite_manual"
          :instructions="mission.instructions"
        />

        <hr class="line-card-mission" />

        <engin-and-tools
          :machine_category="mission.machine_category"
          :machine_translation="mission.machine_translation"
          :machine_name="mission.machine_name"
          :machine_number="mission.machine_number"
          :tools="mission.tools"
        />
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonGrid, IonRow } from '@ionic/vue';
import { convertDateStartDateEndToLisibleDate } from '@/services/utils/dateUtils';
import { businessOutline } from 'ionicons/icons';
import Date from '@/components/driver/card/detailCardPlanning/Date';
import DayPart from '@/components/driver/card/detailCardPlanning/DayPart';
import InformationsBuilderAndSite from '@/components/driver/card/detailCardPlanning/InformationsBuilderAndSite';
import EnginAndTools from '@/components/driver/card/detailCardPlanning/EnginAndTools';
import BackgroundHeader from '@/components/driver/card/detailCardPlanning/BackgroundHeader';
import Banner from '@/components/driver/card/detailCardPlanning/Banner';
import { orderMixin } from '@/components/driver/card/mixins/makeOrder';

export default {
  name: 'CardWorksite',
  mixins: [orderMixin],
  components: {
    Banner,
    BackgroundHeader,
    EnginAndTools,
    InformationsBuilderAndSite,
    Date,
    DayPart,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
  },
  props: {
    mission: {},
  },
  setup() {
    return {
      businessOutline,
    };
  },
  computed: {
    date() {
      if (this.mission && this.mission.start && this.mission.end) {
        return convertDateStartDateEndToLisibleDate(
          this.mission.start,
          this.mission.end,
        );
      }
      return '';
    },
  },
};
</script>
