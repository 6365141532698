<template>
  <ion-row>
    <ion-col class="align-machine-card-planning">
      <ion-img
        :class="
          tools ? 'width-machine-card mb-5' : 'mr-5 mb-5 width-machine-card'
        "
        :src="getImageForCategory(machine_category + '-' + machine_translation)"
      />
      <ion-label
        class="ion-text-center text-card-planning"
        v-if="machine_number"
        >{{ machine_name }} - {{ machine_number }}</ion-label
      >
      <ion-label v-else class="ion-text-center text-card-planning">{{
        machine_name
      }}</ion-label>
      <ion-badge v-if="voiture_pilote" color="success">VP</ion-badge>
    </ion-col>

    <ion-col class="align-center" v-if="tools" size="1">
      <ion-icon class="width-add-card-planning" :icon="addOutline" />
    </ion-col>

    <ion-col class="align-tool-card-planning" v-if="tools" size="6">
      <ion-img
        class="width-icon-img-30 mr-5 mb-5"
        src="/assets/icon/tool.svg"
      />
      <ion-label
        class="text-card-planning"
        v-for="tool in tools"
        :key="tool.id"
        >{{ tool.name }}</ion-label
      >
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonLabel,
  IonCol,
  IonRow,
  IonImg,
  IonIcon,
  IonBadge,
} from '@ionic/vue';
import { addOutline } from 'ionicons/icons';
import utils from '@/services/utils/enginUtils';

export default {
  name: 'EnginAndTools',
  components: {
    IonLabel,
    IonCol,
    IonRow,
    IonImg,
    IonIcon,
    IonBadge,
  },
  props: {
    machine_category: String,
    machine_translation: String,
    machine_name: String,
    machine_number: String,
    tools: {},
    voiture_pilote: Boolean,
  },
  setup() {
    return {
      addOutline,
    };
  },
  methods: {
    getImageForCategory: utils.getImageForCategory,
  },
};
</script>

<style scoped></style>
