<template>
  <ion-page>
    <WhiteHeader
      :back-button="true"
      route-back="/settings"
      title="Mes documents"
    />

    <ion-content>
      <ion-list v-if="attachments.length">
        <ion-item
          v-for="(attachment, key) in attachments"
          :key="key"
          @click="displayAttachment(attachment)"
        >
          <ion-img
            class="width-icon-img-30 ml-10"
            :src="getAttachmentIconPath(attachment.extension)"
            slot="start"
          ></ion-img>
          <ion-label>
            {{ attachment.name }}
          </ion-label>
        </ion-item>
      </ion-list>

      <h1 v-else>Aucun document</h1>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonImg,
  toastController,
} from '@ionic/vue';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import axios from 'axios';
import { getParams } from '@/services/users';
import utils from '@/services/utils/Utils';

export default {
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonImg,
    WhiteHeader,
  },

  data() {
    return {
      attachments: [],
    };
  },

  async mounted() {
    axios
      .get('/driver/attachments', await getParams())
      .then(res => {
        this.attachments = res.data;
      })
      .catch(async () => {
        const toast = await toastController.create({
          message: 'Impossible de récuperer vos documents',
          duration: 3000,
          color: 'danger',
          position: 'middle',
        });

        await toast.present();
      });
  },

  methods: {
    getAttachmentIconPath: utils.getAttachmentIconPath,

    async displayAttachment(attachment) {
      const response = await axios.get(
        '/attachment/' + attachment.id,
        await getParams(),
      );
      const toast = await toastController.create({
        buttons: [
          {
            text: 'Cliquez ici',
            handler: () => {
              window.open(response.data, '_blank');
            },
          },
        ],
        message: 'Votre fichier est prêt !',
        duration: 15000,
        color: 'primary',
        position: 'bottom',
      });
      await toast.present();
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  height: 100%;
  font-size: 24px;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Indicate clicable, for desktop users
ion-item {
  cursor: pointer;
}
</style>
