<template>
  <CardWorksite v-if="mission.type === 'reservation'" :mission="mission" />
  <CardAbsence v-else-if="mission.type === 'absence'" :mission="mission" />
  <CardDelivery v-else-if="mission.type === 'livraison'" :mission="mission" />
  <CardTransfer v-else-if="mission.type === 'transfert'" :mission="mission" />
</template>

<script>
import CardTransfer from '@/components/driver/card/CardTransfer';
import CardDelivery from '@/components/driver/card/CardDelivery';
import CardWorksite from '@/components/driver/card/CardWorksite';
import CardAbsence from '@/components/driver/card/CardAbsence';

export default {
  name: 'ChooseGoodCard',
  components: {
    CardTransfer,
    CardDelivery,
    CardWorksite,
    CardAbsence,
  },
  props: ['mission'],
};
</script>
