const dayjs = require('dayjs');
require('dayjs/locale/fr');
dayjs.locale('fr');

const mixinFillingAllObject = {
  methods: {
    fillingAllObject(key, obj) {
      if (key !== dayjs(this.start).format('YYYYMMDD') || !this.initialized) {
        return;
      }

      for (const keyTimesHours in obj) {
        if (key !== keyTimesHours) {
          obj[keyTimesHours] = obj[key];
        }
      }
    },
  },
};

export { mixinFillingAllObject };
