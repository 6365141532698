const TEXTAREA = 1;
const SELECT = 2;
const DATE = 3;
const DATERANGE = 4;
const BOOLEAN = 5;
const NUMBER = 6;
const CURRENCY = 7;
const FILE = 8;
const COMPUTED = 9;
const TIMESTAMP_NUMBER = 10;

export default {
  TEXTAREA,
  SELECT,
  DATE,
  DATERANGE,
  BOOLEAN,
  NUMBER,
  TIMESTAMP_NUMBER,
  CURRENCY,
  FILE,
  COMPUTED,
};
