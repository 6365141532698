<template>
  <div v-if="part" class="day-part mt-5 mb-10">
    <span v-if="part === '1-morning'">Poste du matin</span>
    <span v-if="part === '2-afternoon'">Poste de l'après-midi</span>
    <span v-if="part === '3-night'">Poste de nuit</span>
  </div>
</template>

<script>
export default {
  name: 'DayPart',
  props: ['part'],
};
</script>

<style lang="scss" scoped>
.day-part {
  display: inline-flex;
  background-color: #3490dc;
  color: white;
  font-weight: bold;
  padding: 3px 4px 4px;
  margin: 0 9px;
  border-radius: 4px;
  align-self: center;
  font-size: 10px;
  line-height: 1;
}
</style>
