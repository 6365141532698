<template>
  <div>
    <ion-row
      class="attachments"
      v-for="(attachment, key) in attachments"
      :key="key"
      @click="showAttachment(attachment)"
    >
      <ion-img
        v-show="key === 0 || showMore"
        :src="getAttachmentIconPath(attachment.extension)"
      ></ion-img>
      <ion-label v-show="key === 0 || showMore">
        {{ attachment.name }}
      </ion-label>
    </ion-row>

    <ion-row
      v-if="attachments && attachments.length && attachments.length > 1"
      class="ion-justify-content-center"
    >
      <ion-button size="small" fill="outline" @click="showMore = !showMore">
        {{ showMore ? 'Voir moins' : 'Voir plus' }}
        <ion-icon
          slot="end"
          class="show-more-icon"
          :class="{ expanded: showMore }"
          :icon="chevronDown"
        ></ion-icon>
      </ion-button>
    </ion-row>
  </div>
</template>

<script>
import {
  IonRow,
  IonButton,
  IonIcon,
  IonImg,
  IonLabel,
  toastController,
} from '@ionic/vue';
import { chevronDown } from 'ionicons/icons';
import axios from 'axios';
import { getParams } from '@/services/users';
import utils from '@/services/utils/Utils';

export default {
  components: {
    IonRow,
    IonButton,
    IonIcon,
    IonImg,
    IonLabel,
  },

  setup() {
    return {
      chevronDown,
    };
  },

  props: {
    attachments: Array,
  },

  data() {
    return {
      showMore: false,

      attachmentLink: null,
      toastButtons: [
        {
          text: 'Cliquez ici',
          handler: () => {
            window.open(this.attachmentLink, '_blank');
            this.attachmentLink = null;
          },
        },
      ],
    };
  },

  methods: {
    getAttachmentIconPath: utils.getAttachmentIconPath,

    /**
     * Get readable link from attachment
     * Then, download file from this link
     */
    async showAttachment(attachment) {
      if (!attachment.id) return;

      // get file link
      const response = await axios.get(
        '/attachment/' + attachment.id,
        await getParams(),
      );

      /**
       * On iOS, our freshly generated links are not allowed to open
       * Because there is a little wait between the click and when we try to open.
       * So we use a toast has a workaround.
       *
       * We can not use a simple href, because we need Sanctum token in headers
       */
      this.attachmentLink = response.data;

      const toast = await toastController.create({
        buttons: this.toastButtons,
        message: 'Votre fichier est prêt !',
        duration: 15000,
        color: 'primary',
        position: 'bottom',
      });

      await toast.present();
    },
  },
};
</script>

<style scoped lang="scss">
.attachments {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;

  ion-img {
    max-width: 32px;
    height: 32px;
    margin: 1rem;
  }

  ion-label {
    font-size: 12px;
  }
}

.show-more-icon {
  transition: 0.3s transform;

  &.expanded {
    transform: rotate(-180deg);
  }
}

ion-button {
  text-transform: none;
  --border-color: #fbbf24;
  --color: #4b5563;
}
</style>
