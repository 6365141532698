<template>
  <ion-page>
    <WhiteHeader
      :back-button="false"
      title="Mes missions"
      v-model:temporality="$data.temporality"
      v-model:missionSelected="$data.missionSelected"
    />
    <ion-content ref="content">
      <ion-refresher
        id="refresher"
        slot="fixed"
        @ionRefresh="doRefresh($event)"
        @click="doRefresh($event)"
        ><ion-refresher-content></ion-refresher-content
      ></ion-refresher>

      <div
        :class="{
          'list-card': true,
          'reversed': temporality === 'past',
        }"
      >
        <div v-for="mission in planning" :key="mission.id">
          <ChooseGoodCard
            class="card-planning"
            :mission="mission"
            v-if="showMission(mission.start, mission.end)"
          />
        </div>
      </div>

      <ion-label
        v-if="planning.length === 0 || $data.missionSelected === false"
        class="align-text-center-screen"
        >Vous n'avez aucune mission pour le moment</ion-label
      >
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import {
  IonContent,
  IonPage,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/vue';
import { forceRefreshPlanning, getPlanning } from "@/services/driver/missionsOrders";
import { ref } from 'vue';
import WhiteHeader from "@/components/driver/header/WhiteHeader";
import {futureDate, pastDate} from "@/services/utils/dateUtils";
import ChooseGoodCard from "@/components/driver/planning/ChooseGoodCard";

export default {
  name: 'PlanningChauffeur',
  components: {
    ChooseGoodCard,
    WhiteHeader,
    IonContent,
    IonPage,
    IonLabel,
    IonRefresher,
    IonRefresherContent,
  },
  data() {
    return {
      planning: {},
      temporality: 'present',
      missionSelected: false,
    }
  },
  setup() {
    const content = ref();

    return { content }
  },

  // Launch auto-refresh when page gets displayed
  ionViewDidEnter() {
    this.doRefresh();

    this.refreshInterval = setInterval(() => {
      this.doRefresh();
    }, 60 * 1000);
  },

  // Stop auto-refresh when page quits
  ionViewDidLeave() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },

  async mounted() {
    this.planning = await getPlanning();
  },
  methods: {
    async doRefresh(event) {
      try {
        this.planning = await forceRefreshPlanning();
      } catch {
        console.log('Unable to force refresh planning.')
      }
      if (event && event.target && event.target.complete) {
        event.target.complete();
      }
    },
    /**
     * Return the temporality of a mission
     * @param start Date when start mission
     * @param end Date when end mission
     * @returns {string} past/present/future
     */
    getTemporality(start, end) {
      if (pastDate(end)) {
        return 'past';
      }
      return futureDate(start) ? 'future' : 'present';
    },
    /**
     * Allows to know if a mission can be displayed according to the selected time frame, Also
     * updates the variable for whether a mission or missions are displayed
     * @param missionStart Date when start mission
     * @param missionEnd Date when end mission
     * @returns {boolean} true/false
     */
    showMission(missionStart, missionEnd) {
      if (this.getTemporality(missionStart, missionEnd) === this.temporality) {
        this.missionSelected = true;
        return true;
      }
      return false;
    }
  },
}
</script>
<style lang="scss" scoped>
.list-card {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;

  &.reversed {
    flex-direction: column-reverse;
  }
}
</style>
