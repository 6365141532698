<template>
  <ion-header translucent="true">
    <ion-toolbar>
      <ion-buttons v-if="backButton" slot="start">
        <ion-back-button color="dark" :default-href="routeBack">
          <ion-icon slot="icon-only" :icon="arrowBackCircleOutline" />
        </ion-back-button>
      </ion-buttons>

      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>

    <ion-segment
      mode="md"
      class="segment-planning"
      v-if="this.$route.name === 'PlanningDriver'"
      :value="temporality"
      @click="setTemporality($event.target.value)"
    >
      <ion-segment-button value="past">
        <ion-label>Terminées</ion-label>
      </ion-segment-button>

      <ion-segment-button value="present">
        <ion-label>Aujourd'hui</ion-label>
      </ion-segment-button>

      <ion-segment-button value="future">
        <ion-label>À venir</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from '@ionic/vue';
import { arrowBackCircleOutline } from 'ionicons/icons';

export default {
  name: 'WhiteHeader',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel,
  },
  props: {
    backButton: Boolean,
    routeBack: String,
    title: String,
    temporality: String,
    missionSelected: Boolean,
  },
  setup() {
    return {
      arrowBackCircleOutline,
    };
  },
  methods: {
    /**
     * Set the temporality of segment
     * @param value // New Temporality of segment
     */
    setTemporality(value) {
      this.$emit('update:temporality', value);
      this.$emit('update:missionSelected', false);
    },
  },
};
</script>
