<template>
  <ion-card-header :class="cssBackground">
    <ion-label class="title-card-planning ml-10">{{ name }}</ion-label>
    <ion-badge
      v-if="mission"
      color="danger"
      class="title-badge-card-planning ml-10 mission-badge"
    >
      {{ mission }}
    </ion-badge>
    <ion-badge
      v-if="order"
      color="danger"
      class="title-badge-card-planning ml-10"
    >
      {{ order }}
    </ion-badge>
  </ion-card-header>
</template>

<script>
import {
  IonLabel,
  IonCardHeader,
  IonBadge,
  //IonImg
} from '@ionic/vue';
export default {
  name: 'BackgroundHeader',
  components: {
    IonLabel,
    IonCardHeader,
    IonBadge,
    //IonImg,
  },
  props: {
    type: String,
    reason: String,
    order: String,
    mission: String,
  },
  data() {
    return {
      name: '',
      cssBackground: '',
    };
  },
  mounted() {
    if (this.type === 'delivery') {
      this.name = 'Livraison';
      this.cssBackground = 'background-delivery';
    } else if (this.type === 'transfer') {
      this.name = 'Transfert';
      this.cssBackground = 'background-transfer';
    } else if (this.type === 'absence') {
      this.cssBackground = 'background-cp';
      this.name = this.reason;
      if (
        this.reason === 'Visite médicale' ||
        this.reason === 'Accident du travail' ||
        this.reason === 'Arrêt maladie'
      ) {
        this.cssBackground = 'background-medical-absence';
      } else if (this.reason === 'Intempéries') {
        this.cssBackground = 'background-bad-weather';
      } else if (this.reason.includes('Formation')) {
        this.cssBackground = 'background-formation';
      } else if (this.reason === 'Atelier') {
        this.cssBackground = 'background-atelier';
      }
    } else {
      this.name = 'Chantier';
      this.cssBackground = 'background-worksite';
    }
  },
};
</script>
