<template>
  <ion-row>
    <ion-col size="1" class="justify-content-center mt-5">
      <ion-img
        v-if="type === 'worksite'"
        class="width-icon-img-16"
        src="/assets/icon/worksite.png"
      ></ion-img>

      <ion-img
        v-else-if="type === 'start'"
        class="width-icon-img-16"
        src="/assets/icon/truck.png"
      ></ion-img>

      <ion-img
        v-else
        class="width-icon-img-16"
        src="/assets/icon/pin.svg"
      ></ion-img>
    </ion-col>

    <ion-col class="ml-10">
      <div class="row-align-center">
        <ion-label class="builder-card-planning">
          {{ builder }}
          <ion-badge
            color="danger"
            class="badge-consignes-card-planning ml-10"
            v-if="instructions"
            >Consignes</ion-badge
          >

          <ion-badge
            v-if="coupon_company && coupon_company === type_client"
            color="success"
            class="badge-consignes-card-planning ml-10"
            >Client</ion-badge
          ></ion-label
        >
      </div>

      <ion-label v-if="!city && !name" class="text-card-planning">
        {{ manual }}
      </ion-label>

      <ion-label v-else>
        {{ [name, city].filter(v => !!v).join(', ') }}
      </ion-label>
    </ion-col>
  </ion-row>
</template>

<script>
import { IonRow, IonCol, IonImg, IonLabel, IonBadge } from '@ionic/vue';

export default {
  name: 'InformationsBuilderAndSite',
  components: {
    IonRow,
    IonCol,
    IonImg,
    IonLabel,
    IonBadge,
  },
  props: {
    /** the type can take 3 values:
     * - worksite : The mission is a site
     * - start : the mission is the departure point of a transfer or delivery
     * - finish : The mission is the arrival place of a transfer or a delivery
     */
    type: String,

    // Company receiving the services
    builder: String,

    // Name of the site if there is one
    name: String,

    // City of the site if there is one
    city: String,

    // Manually entered location of the site if there is one
    manual: String,

    instructions: String,
    coupon_company: String,
    type_client: String,
  },
};
</script>
