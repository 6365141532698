<template>
  <ion-card color="light" class="card-planning-shadow">
    <background-header type="absence" :reason="mission.reason" />

    <ion-card-content class="padding-card-ion-card-content">
      <ion-grid>
        <ion-row>
          <Date :date="date" />
        </ion-row>

        <ion-row v-if="mission.instructions">
          <ion-col>
            <ion-label class="text-card-planning">{{
              mission.instructions
            }}</ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
} from '@ionic/vue';
import { convertDateStartDateEndToLisibleDate } from '@/services/utils/dateUtils';
import Date from '@/components/driver/card/detailCardPlanning/Date';
import BackgroundHeader from '@/components/driver/card/detailCardPlanning/BackgroundHeader';

export default {
  name: 'CardAbsence',
  components: {
    BackgroundHeader,
    Date,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
  },
  props: {
    mission: {},
  },
  methods: {
    /**
     * Go to the absence details page
     */
    goToDetail() {
      this.$router.push(`/absence/${this.mission.id}`);
    },
  },
  computed: {
    date() {
      if (this.mission && this.mission.start && this.mission.end) {
        return convertDateStartDateEndToLisibleDate(
          this.mission.start,
          this.mission.end,
        );
      }
      return '';
    },
  },
};
</script>

<style scoped></style>
