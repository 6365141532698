<template>
  <!-- case: event on one day -->
  <ion-row v-if="startDefault === endDefault" class="mb-5">
    <ion-col>
      <ion-item class="item-floating item-border-grey">
        <ion-label position="stacked" class="note-form-driver">Début</ion-label>

        <ion-datetime-button
          datetime="datetime-start-same"
          disabled="true"
        ></ion-datetime-button>

        <ion-modal :keep-contents-mounted="true">
          <ion-datetime
            id="datetime-start-same"
            presentation="date"
            name="start"
            :value="start"
            disabled="true"
            required="true"
          ></ion-datetime>
        </ion-modal>
      </ion-item>
    </ion-col>

    <ion-col>
      <ion-item class="item-floating item-border-grey">
        <ion-label position="stacked" class="note-form-driver">Fin</ion-label>

        <ion-datetime-button
          datetime="datetime-end-same"
          disabled="true"
        ></ion-datetime-button>

        <ion-modal :keep-contents-mounted="true">
          <ion-datetime
            id="datetime-end-same"
            presentation="date"
            name="end"
            :value="end"
            disabled="true"
            required="true"
          ></ion-datetime>
        </ion-modal>
      </ion-item>
    </ion-col>
  </ion-row>

  <!-- case: event on more than one day -->
  <ion-row v-else class="mb-5">
    <ion-col>
      <ion-item class="item-floating">
        <ion-label position="stacked" class="note-form-driver">Début</ion-label>

        <ion-datetime-button
          datetime="datetime-start"
          :disabled="oldRentalCouponSending"
        ></ion-datetime-button>

        <ion-modal ref="modalStart" :keep-contents-mounted="true">
          <ion-datetime
            id="datetime-start"
            presentation="date"
            name="start"
            @ionChange="onChangeStart($event.target.value)"
            :value="start"
            :min="startDefault"
            :max="end"
            :disabled="oldRentalCouponSending"
            required="true"
          ></ion-datetime>
        </ion-modal>
      </ion-item>
    </ion-col>

    <ion-col>
      <ion-item class="item-floating">
        <ion-label position="stacked" class="note-form-driver">Fin</ion-label>

        <ion-datetime-button
          datetime="datetime-end"
          :disabled="oldRentalCouponSending"
        ></ion-datetime-button>

        <ion-modal ref="modalEnd" :keep-contents-mounted="true">
          <ion-datetime
            id="datetime-end"
            presentation="date"
            name="end"
            @ionChange="onChangeEnd($event.target.value)"
            :value="end"
            :min="start"
            :max="endDefault"
            :disabled="oldRentalCouponSending"
            required="true"
          ></ion-datetime>
        </ion-modal>
      </ion-item>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonModal,
} from '@ionic/vue';

const dayjs = require('dayjs');
require('dayjs/locale/fr');
dayjs.locale('fr');

export default {
  name: 'Date',
  components: {
    IonRow,
    IonCol,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonItem,
    IonLabel,
  },
  emits: ['updateDateDependent'],
  props: [
    'start',
    'end',
    'startDefault',
    'endDefault',
    'oldRentalCouponSending',
  ],
  methods: {
    onChangeStart(date) {
      this.$emit('update:start', date);
      this.$emit('updateDateDependent');

      // hide start modal
      this.$refs.modalStart.$el.dismiss();
    },
    onChangeEnd(date) {
      this.$emit('update:end', date);
      this.$emit('updateDateDependent');

      // hide end modal
      this.$refs.modalEnd.$el.dismiss();
    },
  },
};
</script>
