<template>
  <ion-col class="detail-transfer-delivery-card-planning">
    <div class="align-line-icon-badge" v-if="machines || machines_manual">
      <ion-img
        class="width-icon-img-30 mr-5"
        src="/assets/icon/machine.svg"
      ></ion-img>
      <div class="align-badge-card-planning">
        <ion-badge
          color="tertiary"
          class="margin-3"
          v-for="machine in machines"
          :key="machine.id"
          >{{
            [machine.name, machine.fleet_number].filter(m => !!m).join(' - ')
          }}
        </ion-badge>
        <ion-badge
          color="tertiary"
          class="margin-3"
          v-for="machine in machines_manual"
          :key="machine"
          >{{ machine }}
        </ion-badge>
      </div>
    </div>

    <div class="align-line-icon-badge mt-5" v-if="tools || tools_manual">
      <ion-img
        class="width-icon-img-30 mr-5"
        src="/assets/icon/tool.svg"
      ></ion-img>
      <div class="align-badge-card-planning">
        <ion-badge
          color="secondary"
          class="margin-3"
          v-for="tool in tools"
          :key="tool.name"
          >{{ tool.name }}</ion-badge
        >
        <ion-badge
          color="secondary"
          class="margin-3"
          v-for="tool in tools_manual"
          :key="tool"
          >{{ tool }}</ion-badge
        >
      </div>
    </div>
  </ion-col>
</template>

<script>
import { IonCol, IonBadge, IonImg } from '@ionic/vue';

export default {
  name: 'DetailTransfer',
  components: {
    IonCol,
    IonBadge,
    IonImg,
  },
  props: {
    machines: Array,
    machines_manual: Array,
    tools: Array,
    tools_manual: Array,
  },
};
</script>

<style scoped></style>
