const orderMixin = {
  methods: {
    makeTransportOrder(card) {
      let length = Object.keys(this.mission.transport_items).length;
      return length > 1 ? card.order + '/' + length : '';
    },
    makeMissionOrder() {
      return this.mission.order ? 'Mission ' + this.mission.order : '';
    },
  },
};

export { orderMixin };
