<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-note>A transférer</ion-note>
      </ion-col>
    </ion-row>

    <ion-row>
      <detail-transfer
        class="mt-10 ml-10"
        :machines="machines"
        :machines_manual="machines_manual"
        :tools="tools"
        :tools_manual="tools_manual"
      />
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonNote } from '@ionic/vue';
import DetailTransfer from '@/components/driver/common/DetailTransfer';

export default {
  name: 'Transfer',
  components: {
    DetailTransfer,
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
  },
  props: {
    machines: Array,
    machines_manual: Array,
    tools: Array,
    tools_manual: Array,
  },
};
</script>

<style scoped></style>
