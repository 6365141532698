<template>
  <div class="mt-5 mb-10">
    <ion-label class="date-card-planning">{{ date }}</ion-label>
  </div>
</template>

<script>
import { IonLabel } from '@ionic/vue';

export default {
  name: 'Date',
  components: {
    IonLabel,
  },
  props: {
    date: String,
  },
};
</script>
