<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-note>Client</ion-note>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="align-detail-text">
        <ion-icon class="icones-detail-evenement" :icon="businessOutline" />
        <ion-label class="text-align-center">{{ nameCompany }}</ion-label>
      </ion-col>

      <ion-col class="align-detail-text" v-if="manager">
        <ion-img
          class="icones-detail-evenement"
          src="/assets/icon/manager.svg"
        />

        <div class="ion-text-center">
          <ion-label class="text-align-center">
            {{ manager }}
          </ion-label>
          <br />
          <ion-label class="text-petit-detail-telephone">
            {{ manager_phone }}
          </ion-label>
        </div>
      </ion-col>

      <ion-col class="align-detail-text" v-if="manager2">
        <ion-img
          class="icones-detail-evenement"
          src="/assets/icon/manager2.svg"
        />

        <div class="ion-text-center">
          <ion-label class="text-align-center">
            {{ manager2 }}
          </ion-label>
          <br />
          <ion-label class="text-petit-detail-telephone">
            {{ manager2_phone }}
          </ion-label>
        </div>
      </ion-col>
    </ion-row>

    <ion-row v-if="need_signature_source">
      <ion-badge color="secondary">Signature au chargement</ion-badge>
    </ion-row>

    <ion-row class="internal-references" v-if="isReservation">
      <ion-col class="mt-15 column">
        <ion-note>Réf chantier :</ion-note>
        <ion-label v-if="worksite_reference">{{
          worksite_reference
        }}</ion-label>
        <ion-label v-else class="text-red">Aucune</ion-label>
      </ion-col>
      <ion-col class="mt-15 column">
        <ion-note>N° commande :</ion-note>
        <ion-label v-if="order_number">{{ order_number }}</ion-label>
        <ion-label v-else class="text-red">Aucun</ion-label>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="mt-15">
        <ion-note>Consignes</ion-note>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="mt-0">
        <ion-label class="text-red ion-text-wrap">{{
          instructions || 'Aucune'
        }}</ion-label>
      </ion-col>
    </ion-row>

    <attachments-list :attachments="attachments" />

    <ion-row>
      <ion-col class="mt-15">
        <ion-note>Chantier</ion-note>
      </ion-col>
    </ion-row>

    <ion-row v-if="worksite_name && worksite_name !== worksite_address">
      <ion-col>
        <ion-label>{{ worksite_name }}</ion-label>
      </ion-col>
    </ion-row>

    <ion-row v-if="worksite_city || worksite_address">
      <ion-col class="mt-0">
        <ion-label>{{
          [worksite_city, worksite_address].filter(v => !!v).join(', ')
        }}</ion-label>
      </ion-col>
    </ion-row>

    <ion-row v-if="!worksite_name && !worksite_address && !worksite_city">
      <ion-col>
        <ion-label>{{ worksite_manual || 'Adresse non définie' }}</ion-label>
      </ion-col>
    </ion-row>

    <ion-row v-if="worksite_coordinates">
      <ion-col class="mt-30 mb-20">
        <div ref="informationMap" class="width-map"></div>
      </ion-col>
    </ion-row>

    <ion-row v-if="worksite_coordinates || manager_phone">
      <ion-col v-if="worksite_coordinates">
        <div class="align-map-call-bouton" @click="openGMap">
          <ion-icon class="width-icon-img-30" :icon="mapOutline" />
          <ion-label class="mt-5">Itinéraire</ion-label>
        </div>
      </ion-col>

      <ion-col v-if="manager_phone">
        <a class="align-map-call-bouton" :href="'tel:' + manager_phone">
          <ion-icon class="width-icon-img-30" :icon="callOutline" />
          <ion-label class="mt-5">Appeler le CC</ion-label>
        </a>
      </ion-col>

      <ion-col v-if="manager2_phone">
        <a class="align-map-call-bouton" :href="'tel:' + manager2_phone">
          <ion-icon class="width-icon-img-30" :icon="callOutline" />
          <ion-label class="mt-5">Appeler le CTX</ion-label>
        </a>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonNote,
  IonLabel,
  IonIcon,
  IonImg,
  IonBadge,
} from '@ionic/vue';
import {
  businessOutline,
  personOutline,
  mapOutline,
  callOutline,
} from 'ionicons/icons';
import L from 'leaflet';
import utils from '@/services/utils/Utils';
import AttachmentsList from '@/components/driver/detailMission/sub-components/AttachmentsList';

export default {
  name: 'Information',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
    IonLabel,
    IonIcon,
    IonImg,
    IonBadge,
    AttachmentsList,
  },

  setup() {
    return {
      businessOutline,
      personOutline,
      mapOutline,
      callOutline,
    };
  },

  data() {
    return {
      map: null,
    };
  },

  props: {
    nameCompany: String,
    manager: String,
    manager_phone: String,
    manager2: String,
    manager2_phone: String,
    worksite_city: String,
    worksite_name: String,
    worksite_address: String,
    worksite_manual: String,
    worksite_coordinates: {},
    instructions: String,
    order_number: String,
    worksite_reference: String,
    isReservation: Boolean,
    attachments: Array,
    need_signature_source: Boolean,
  },

  methods: {
    initMap() {
      if (!this.map && this.worksite_coordinates) {
        let map = L.map(this.$refs.informationMap).setView(
          [
            this.worksite_coordinates.coordinates[1],
            this.worksite_coordinates.coordinates[0],
          ],
          10,
        );

        L.tileLayer(
          'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}',
          {
            attribution:
              'feat &copy;<a href="https://www.openstreetmap.org/">OpenStreetMap</a> And ©<a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            accessToken:
              'pk.eyJ1IjoiYnJ2bm8iLCJhIjoiY2txa3AxazZmMTdzMTJvbnhldXlkdTA0diJ9.0WjsXRhs_-ooY4HGK_ocJg',
          },
        ).addTo(map);

        L.marker(
          [
            this.worksite_coordinates.coordinates[1],
            this.worksite_coordinates.coordinates[0],
          ],
          {
            icon: L.divIcon({
              className: 'own-marker',
              iconSize: 18,
              iconAnchor: [9, 9],
            }),
          },
        ).addTo(map);

        this.map = map;
      }

      if (this.map) {
        this.map.invalidateSize();
      }
      setTimeout(() => {
        if (this.map) {
          this.map.invalidateSize();
        }
      }, 2000);
    },

    removeMap() {
      if (this.map) {
        this.map.remove();
      }
      this.map = null;
    },

    openGMap() {
      utils.navigateTo(
        this.worksite_coordinates.coordinates[1],
        this.worksite_coordinates.coordinates[0],
      );
    },
  },
};
</script>
