<template>
  <ion-page>
    <WhiteHeader :back-button="true" route-back="/settings" title="Aide" />
    <ion-content fullscreen="true">
      <ion-label class="title-help">
        <strong>Vous avez des questions&nbsp;?</strong><br />
        Nous sommes là pour vous aider. Contactez-nous et nous ferons notre
        possible pour vous répondre.
      </ion-label>
      <ion-list>
        <ion-item href="mailto:support@locatiers.fr">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="dark"
            slot="start"
            :icon="mailOutline"
          ></ion-icon>
          <ion-label>Envoyez-nous un mail</ion-label>
        </ion-item>
        <ion-item href="sms:0766166469">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="dark"
            slot="start"
            :icon="chatboxEllipsesOutline"
          ></ion-icon>
          <ion-label>Envoyez-nous un SMS</ion-label>
        </ion-item>
        <ion-item href="tel:0766166469">
          <ion-icon
            class="width-icon-img-30 ml-10"
            color="dark"
            slot="start"
            :icon="callOutline"
          ></ion-icon>
          <ion-label>Appelez-nous</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import {
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
} from '@ionic/vue';
import {
  mailOutline,
  chatboxEllipsesOutline,
  callOutline,
} from 'ionicons/icons';

export default {
  name: 'Help',
  components: {
    WhiteHeader,
    IonPage,
    IonContent,
    IonItem,
    IonList,
    IonIcon,
    IonLabel,
  },
  setup() {
    return {
      callOutline,
      chatboxEllipsesOutline,
      mailOutline,
    };
  },
};
</script>

<style scoped>
.title-help {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 60%;
  font-size: 20px;
  padding: 0 20px 30px 20px;
}
</style>
