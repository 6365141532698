<template>
  <div class="background-banner-green">BL</div>
</template>

<script>
export default {
  name: 'Banner',
};
</script>

<style scoped>
.background-banner-green {
  position: absolute;
  top: 0;
  right: 8px;

  background-size: 100%;
  width: 26px;
  height: 40px;
  padding: 2px 4px 20px 4px;
  background: url('/assets/icon/banner-green.svg') no-repeat center;
  text-align: center;
  color: white;
  font-weight: 700;
}
</style>
