<template>
  <div class="fab-custom" @click="goToGoodPage()">
    <label v-if="hasQuestions">Rapport mission</label>
    <label v-else>Créer un BL</label>
  </div>
</template>

<script>
export default {
  name: 'NewRentalCoupon',

  props: {
    typeMission: String,
    hasQuestions: Boolean,
  },

  methods: {
    goToGoodPage() {
      const toAdd = this.hasQuestions ? 'report' : 'bonLocation/create';
      this.$router.push(`${this.$route.fullPath}/${toAdd}`);
    },
  },
};
</script>

<style scoped lang="scss">
.fab-custom {
  position: sticky;
  // do not remove this version because we need it for safari
  position: -webkit-sticky;
  width: 100%;
  text-align: center;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcd34d;
  border-radius: 10px;
  padding: 16px 24px 16px 24px;
  bottom: 0;
  right: 0;
  z-index: 1100;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);

  ion-icon {
    margin-right: 1rem;
    width: 18px;
    height: 18px;
  }

  label {
    font-size: 14px;
  }
}
</style>
