<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-note>Mes bons de location</ion-note>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div v-if="rentalCoupons && rentalCoupons.length">
    <rental-coupon
      v-for="rentalCoupon in rentalCoupons"
      :type-mission="typeMission"
      :rental-coupon="rentalCoupon"
      v-bind:key="rentalCoupon.id"
    />
  </div>
  <ion-grid v-else>
    <ion-row>
      <ion-col class="text-align-center mt-15 mb-10">
        <ion-label>Aucun bon de créé pour l'instant</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonNote, IonLabel } from '@ionic/vue';
import RentalCoupon from '@/components/driver/card/RentalCoupon';
export default {
  name: 'ListeRentalCoupon',
  components: {
    RentalCoupon,
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
    IonLabel,
  },
  props: {
    rentalCoupons: {},
    typeMission: String,
  },
};
</script>
