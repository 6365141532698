<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-note>A livrer</ion-note>
      </ion-col>
    </ion-row>
    <ion-row>
      <detail-delivery
        class="mt-10 ml-10"
        :materials="materials"
        :rounds="rounds"
      />
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonNote } from '@ionic/vue';
import { refreshOutline } from 'ionicons/icons';
import DetailDelivery from '@/components/driver/common/DetailDelivery';

export default {
  name: 'Delivery',
  components: {
    DetailDelivery,
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
  },
  props: {
    materials: Array,
    rounds: String,
  },
  setup() {
    return {
      refreshOutline,
    };
  },
};
</script>

<style scoped></style>
