<template>
  <ion-row>
    <ion-col>
      <ion-item class="item-floating">
        <ion-label position="floating">Réf chantier</ion-label>
        <ion-input
            :value="worksite_reference"
            @ionChange="$emit('update:worksite_reference', $event.target.value)"
        ></ion-input>
      </ion-item>
    </ion-col>

    <ion-col>
      <ion-item class="item-floating">
        <ion-label position="floating">N° commande</ion-label>
        <ion-input
            :value="order_number"
            @ionChange="$emit('update:order_number', $event.target.value)"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>

  <hr>
</template>

<script>
import { IonRow, IonCol, IonInput, IonItem, IonLabel } from '@ionic/vue';

export default {
  components: {
    IonRow,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
  },
  props: ['worksite_reference', 'order_number'],
};
</script>

<style scoped>
  hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
</style>
