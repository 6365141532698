<template>
  <ion-grid v-if="start === end">
    <ion-row>
      <ion-col>
        <ion-note>Date</ion-note>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-label>{{ start }}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid v-else>
    <ion-row>
      <ion-col class="align-date-detail-mission">
        <ion-note class="mb-10">Début</ion-note>
        <ion-label>{{ start }}</ion-label>
      </ion-col>
      <ion-col class="align-date-detail-mission border-left-grey">
        <ion-note class="mb-10">Fin</ion-note>
        <ion-label>{{ end }}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonNote, IonLabel } from '@ionic/vue';

export default {
  name: 'Date',
  components: { IonGrid, IonRow, IonCol, IonNote, IonLabel },
  props: {
    start: String,
    end: String,
  },
};
</script>
