<template>
  <ion-col class="detail-transfer-delivery-card-planning">
    <div class="align-line-icon-badge">
      <ion-img
        class="width-icon-img-30"
        src="/assets/icon/material.svg"
      ></ion-img>
      <div class="align-badge-card-planning ml-10">
        <ion-badge
          color="tertiary"
          class="margin-3"
          v-for="material in materials"
          :key="material"
          >{{ material }}
        </ion-badge>
      </div>
    </div>
    <div class="align-line-icon-badge mt-5">
      <ion-img
        class="width-icon-img-25"
        src="/assets/icon/rounds.svg"
      ></ion-img>
      <div class="align-badge-card-planning ml-15">
        <ion-badge color="secondary" class="margin-3">{{ rounds }} </ion-badge>
      </div>
    </div>
  </ion-col>
</template>

<script>
import { IonCol, IonImg, IonBadge } from '@ionic/vue';

export default {
  name: 'DetailDelivery',
  components: {
    IonCol,
    IonImg,
    IonBadge,
  },
  props: {
    materials: Array,
    rounds: String,
  },
};
</script>

<style scoped></style>
