<template>
  <ion-col size="1" class="align-center">
    <ion-img
      class="arrow-card-planning"
      src="/assets/icon/arrow-planning.svg"
    ></ion-img>
  </ion-col>
</template>

<script>
import { IonCol, IonImg } from '@ionic/vue';
export default {
  name: 'ArrowDeliveryAndTransfer',
  components: {
    IonCol,
    IonImg,
  },
};
</script>
