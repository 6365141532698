<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-note>Engin</ion-note>
        <div
          :class="
            tools
              ? 'align-detail-img mt-15'
              : 'justify-content-center align-detail-img mt-15'
          "
        >
          <ion-img
            class="width-machine-detail-mission mb-5"
            :src="
              getImageForCategory(machine_category + '-' + machine_translation)
            "
          />
          <div class="text-align-center">
            <ion-label class="ml-10"
              >{{
                machine_number
                  ? machine_name + ' - ' + machine_number
                  : machine_name
              }}<br v-if="voiture_pilote" /><ion-badge
                v-if="voiture_pilote"
                color="success"
                class="ml-10"
                >VP</ion-badge
              ></ion-label
            >
          </div>
        </div>
      </ion-col>
      <ion-col v-if="tools" class="border-left-grey">
        <ion-note>Outil(s)</ion-note>
        <div class="align-detail-img mt-13">
          <ion-img
            class="width-icon-img-30 mr-10 mb-5"
            src="/assets/icon/tool.svg"
          />
          <ion-label
            class="text-align-center"
            v-for="tool in tools"
            :key="tool.id"
            >{{ tool.name }}</ion-label
          >
        </div>
      </ion-col>
    </ion-row>

    <attachments-list :attachments="attachments" />
  </ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonNote,
  IonLabel,
  IonImg,
  IonBadge,
} from '@ionic/vue';
import utils from '@/services/utils/enginUtils';
import AttachmentsList from '@/components/driver/detailMission/sub-components/AttachmentsList';

export default {
  name: 'Equipment',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
    IonLabel,
    IonImg,
    IonBadge,
    AttachmentsList,
  },

  props: {
    machine_name: String,
    machine_category: String,
    machine_number: String,
    machine_translation: String,
    machine_poids: String,
    tools: {},
    voiture_pilote: Boolean,
    attachments: Array,
  },

  methods: {
    getImageForCategory: utils.getImageForCategory,
  },
};
</script>
