<template>
  <ion-card
    v-for="card in mission.transport_items"
    :key="card.id"
    color="light"
    class="card-planning-shadow"
    :router-link="'/livraison/' + this.mission.id_transport + '/' + this.mission.id + '/' + card.id"
  >
    <background-header
      type="delivery"
      :order="makeTransportOrder(card)"
      :mission="makeMissionOrder()"
    />

    <ion-card-content class="padding-card-ion-card-content">
      <ion-grid>
        <ion-row>
          <Date :date="date" />
          <DayPart :part="mission.day_part"></DayPart>
          <Banner v-if="card.rental_coupons" />
        </ion-row>

        <informations-builder-and-site
          type="start"
          :builder="card.source"
          :name="card.source_location_name"
          :city="card.source_location_city"
          :instructions="card.source_instructions"
          :coupon_company="card.coupon_company"
          type_client="source"
        />

        <ion-row class="mt-5 mb-5">
          <arrow-delivery-and-transfer />

          <detail-delivery
            class="mt-5 mb-5 ml-10"
            :materials="card.materials"
            :rounds="card.rounds"
          />
        </ion-row>

        <informations-builder-and-site
          type="finish"
          :builder="card.target"
          :name="card.target_location_name"
          :city="card.target_location_city"
          :instructions="card.target_instructions"
          :coupon_company="card.coupon_company"
          type_client="target"
        />

        <hr class="line-card-mission" />

        <engin-and-tools
          :machine_category="mission.machine_category"
          :machine_translation="mission.machine_translation"
          :machine_name="mission.machine_name"
          :machine_number="mission.machine_number"
          :tools="mission.tools"
        />
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonGrid, IonRow } from '@ionic/vue';
import { convertDateStartDateEndToLisibleDate } from '@/services/utils/dateUtils';
import { arrowForwardOutline, addOutline } from 'ionicons/icons';
import DetailDelivery from '@/components/driver/common/DetailDelivery';
import Date from '@/components/driver/card/detailCardPlanning/Date';
import DayPart from '@/components/driver/card/detailCardPlanning/DayPart';
import InformationsBuilderAndSite from '@/components/driver/card/detailCardPlanning/InformationsBuilderAndSite';
import ArrowDeliveryAndTransfer from '@/components/driver/card/detailCardPlanning/ArrowDeliveryAndTransfer';
import EnginAndTools from '@/components/driver/card/detailCardPlanning/EnginAndTools';
import BackgroundHeader from '@/components/driver/card/detailCardPlanning/BackgroundHeader';
import Banner from '@/components/driver/card/detailCardPlanning/Banner';
import { orderMixin } from '@/components/driver/card/mixins/makeOrder';

export default {
  name: 'CardDelivery',
  mixins: [orderMixin],
  components: {
    Banner,
    BackgroundHeader,
    EnginAndTools,
    ArrowDeliveryAndTransfer,
    InformationsBuilderAndSite,
    Date,
    DayPart,
    DetailDelivery,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
  },
  props: {
    mission: {},
  },
  setup() {
    return {
      arrowForwardOutline,
      addOutline,
    };
  },
  computed: {
    date() {
      if (this.mission && this.mission.start && this.mission.end) {
        return convertDateStartDateEndToLisibleDate(
          this.mission.start,
          this.mission.end,
        );
      }
      return '';
    },
  },
};
</script>

<style scoped></style>
